// eslint-disable-next-line no-restricted-imports
import { readInlineData as readInlineDataRelay, graphql as relayGraphql } from 'react-relay';
import { parseJSON } from 'date-fns';
import { GraphqlIncompleteDataError, GraphqlScalarDeserializationError } from './errors';

/**
 * @deprecated use Relay v13's `@required(action: THROW)` directive instead
 */
export const validateEdge = edge => {
  if (edge == null) {
    throw new GraphqlIncompleteDataError('edge is null or undefined');
  }

  if (edge.node == null) {
    throw new GraphqlIncompleteDataError('edge.node is null or undefined');
  }

  return edge.node;
};
/**
 * This should receive an object, not a scalar.
 * @param obj
 * @deprecated use Relay v13's `@required(action: THROW)` directive instead
 */

export const validateRelayObject = obj => {
  if (obj == null) {
    throw new GraphqlIncompleteDataError('object is null');
  }

  for (const [key, value] of Object.entries(obj)) {
    if (value == null) {
      throw new GraphqlIncompleteDataError("object has incomplete data for key \"".concat(key, "\""));
    }
  }

  return obj;
};
export const arrayOfEdges = obj => {
  if (obj == null) {
    throw new GraphqlIncompleteDataError('object doesn\'t have "edges"');
  }

  return (obj.edges || []).map(validateEdge);
};
export const castStringAsSerializedDateTime = str => {
  return str;
};
export const castDateAsSerializedDateTime = date => {
  return castStringAsSerializedDateTime(date.toISOString());
};
export const deserializeGQLDateTime = dt => {
  if (typeof dt !== 'string') {
    throw new GraphqlScalarDeserializationError('DateTime', "\"".concat(dt, "\" is not a string"));
  }

  const date = parseJSON(dt);

  if (!(date instanceof Date && Number.isFinite(date.getTime()))) {
    throw new GraphqlScalarDeserializationError('DateTime', "\"".concat(dt, "\" is invalid"));
  }

  return date;
}; // Re-exporting so we don't need to update all the previous `graphql` imports
// now that we support react-relay's `graphql` export.

export const graphql = relayGraphql;
/**
 * Wrapper around the relay method which fixes an apparent typing error.
 * Fragments with the @inline directive are given the type ReaderInlineDataFragment,
 * but readInlineData won't accept this as an argument without casting.
 */

export const readInlineData = (arg0, arg1) => {
  // TODO: remove this and update reference https://attentivemobile.atlassian.net/browse/WPM-3
  // type mismatch between relay and definitely typed
  return readInlineDataRelay(arg0, arg1 !== null && arg1 !== void 0 ? arg1 : null);
};
/**
 * Generic type guard to determine if the argument has the expected properties of a fragment.
 * Useful for branching logic in methods used in both REST and GQL contexts.
 */

export const isFragment = item => {
  return '__fragments' in item;
};
/**
 * When making a query for a node, if you don't include  '__typename' field, all the other fields requested will be
 * typed as optional, regardless of whether they are nullable or non-nullable.
 *
 * If you do include '__typename', you get an union between the desired type and and empty type with typename '%other'.
 *
 * This utility aids in extracting the desired type from that union.
 */